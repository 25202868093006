import { AuthenticationContext } from '@/plugins/msal';
import _Vue from 'vue';
import { BaseRepository, RepositoryPluginOptions } from './base-repository';

export class GraphRepository extends BaseRepository {
  public async getData(): Promise<string> {
    return this.get<string>('/v1.0/me');
  }
}

export function GraphRepositoryPlugin(Vue: typeof _Vue, options: RepositoryPluginOptions): void {
  Vue.prototype.$graphApi = new GraphRepository(Vue.prototype.$msal as AuthenticationContext, options);
}

declare module 'vue/types/vue' {
  interface Vue {
    $graphApi: GraphRepository;
  }
}
