import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { TimesheetSummary } from '@/models/timesheet-summary';
import { TimesheetStatus } from '@/models/timesheet-status';

@Component
export default class WeekOverview extends Vue {
  @Prop()
  private line: TimesheetSummary;

  private timePeriod(): string {
    return `${this.$dayjs(this.line.startDate).format('dd D MMM ')} ${this.$t('navbar.fromto')} ${this.$dayjs(this.line.endDate).format('dd D MMM \'YY')}`;
  }

  private getStatusString(): string {
    switch (this.line.timesheetStatus) {
      case TimesheetStatus.Draft:
        return this.$t('status.draft').toString();
      case TimesheetStatus.Submitted:
        return this.$t('status.submitted').toString();
      case TimesheetStatus.Deleted:
        return this.$t('status.deleted').toString();
      default:
        return this.$t('status.unknown').toString();
    }
  }

  private linkToWeek() {
    this.$router.push({ name: 'timesheet', params: { timesheetId: this.line.timesheetId } });
  }
}
