import _Vue from 'vue';
import { PublicClientApplication, AuthorizationUrlRequest, SilentRequest, AuthenticationResult, Configuration, LogLevel, AccountInfo, InteractionRequiredAuthError, EndSessionRequest, RedirectRequest, PopupRequest } from '@azure/msal-browser';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: window.env.CLIENTID,
    authority: window.env.AUTHORITY,
    knownAuthorities: [],
    redirectUri: `${window.env.REDIRECTURL}`,
    postLogoutRedirectUri: `${window.env.REDIRECTURL}`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export class AuthenticationContext {
  private authcontext: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
  private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  private tokenSilentRequest: SilentRequest;
  private tokenRedirectRequest: RedirectRequest;
  private profileSilentRequest: SilentRequest;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private idTokenClaims: any | null;

  constructor() {
    this.authcontext = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
    this.idTokenClaims = null;
    this.setRequestObjects();
    _Vue.prototype.$msal = this;
  }

  public run(callback: ()=> void | undefined) {
    this.authcontext.handleRedirectPromise().then((resp: AuthenticationResult | null) => {
      if (resp !== null) {
        this.account = resp.account;
        this.idTokenClaims = resp.idTokenClaims;
      } else {
        this.account = this.getAccount();
      }

      if (this.idTokenClaims === null) {
        this.RefreshIdToken().then(() => {
          this.acquireToken(callback);
        });
      } else {
        this.acquireToken(callback);
      }
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }

  /**
   * Initialize request objects used by this AuthModule.
   */
  private setRequestObjects(): void {
    this.loginRedirectRequest = {
      scopes: [],
      extraScopesToConsent: window.env.SCOPES
    };

    this.profileSilentRequest = {
      scopes: ['openid', 'profile'],
      account: {
        homeAccountId: '',
        localAccountId: '',
        environment: '',
        tenantId: '',
        username: ''
      }
    };

    this.tokenSilentRequest = {
      scopes: window.env.SCOPES,
      account: {
        homeAccountId: '',
        localAccountId: '',
        environment: '',
        tenantId: '',
        username: ''
      }
    };

    this.tokenRedirectRequest = {
      scopes: window.env.SCOPES,
      redirectUri: `${window.env.REDIRECTURL}`
    };
  }

  /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
  private getAccount(): AccountInfo | null {
    const currentAccounts = this.authcontext.getAllAccounts();
    if (currentAccounts === null) {
      return null;
    }

    if (currentAccounts.length > 1) {
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  private login() {
    this.authcontext.loginRedirect(this.loginRedirectRequest);
  }

  public async RefreshIdToken() {
    this.profileSilentRequest.account = this.getAccount() as AccountInfo;
    this.authcontext.acquireTokenSilent(this.profileSilentRequest).then((resp) => {
      this.idTokenClaims = resp.idTokenClaims;
    }, (e) => {
    });
  }

  public logout() {
    this.authcontext.logout();
  }

  public get profile(): AccountInfo {
    if (this.account === null) {
      return {
        homeAccountId: '',
        localAccountId: '',
        environment: '',
        tenantId: '',
        username: '',
        name: ''
      };
    }

    return this.account;
  }

  public isAuthenticated(): boolean {
    return this.idTokenClaims !== null;
  }

  public get language(): string | undefined {
    if (this.idTokenClaims === null) {
      return undefined;
    }

    return this.idTokenClaims.extension_Language;
  }

  public get DateFormat(): string | undefined {
    if (this.idTokenClaims === null) {
      return undefined;
    }

    return this.idTokenClaims.extension_DateFormat;
  }

  public get TimeFormat(): string | undefined {
    if (this.idTokenClaims === null) {
      return undefined;
    }

    return this.idTokenClaims.extension_TimeFormat;
  }

  public async getToken(): Promise<string> {
    this.tokenSilentRequest.account = this.account as AccountInfo;
    return this.getTokenRedirect(this.tokenSilentRequest, this.tokenRedirectRequest);
  }

  private acquireToken (successCallback: ()=> void | undefined) {
    if (this.account === null) {
      this.login();
    } else {
      this.getToken().then((r) => {
        if (successCallback) {
          successCallback();
        }
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(silentRequest: SilentRequest, interactiveRequest: RedirectRequest): Promise<string> {
    try {
      const response = await this.authcontext.acquireTokenSilent(silentRequest);
      this.idTokenClaims = response.idTokenClaims;
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.authcontext.acquireTokenRedirect(interactiveRequest).catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      } else {
        return Promise.reject(e);
      }
    }

    return '';
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $msal: AuthenticationContext;
    $isAuthenticated: () => boolean;
  }
}

const msal = new AuthenticationContext();

export default msal;
