import _Vue from 'vue';
import { BaseRepository, RepositoryPluginOptions } from './base-repository';
import { TimesheetSummary } from '@/models/timesheet-summary';
import { Timesheet } from '@/models/timesheet';
import { DateRange } from '@/models/date-range';
import { AuthenticationContext } from '@/plugins/msal';

export class CrmRepository extends BaseRepository {
  public async getTimesheetsAsync(range: DateRange): Promise<TimesheetSummary[]> {
    return this.post<TimesheetSummary[]>('/api/timesheet/list', range);
  }

  public async getTimesheetAsync(id: string): Promise<Timesheet> {
    return this.get<Timesheet>(`/api/timesheet/get/${id}`);
  }

  public async submitTimesheet(sheet: Timesheet): Promise<Timesheet> {
    return this.post<Timesheet>('/api/timesheet/submit', sheet);
  }

  public async saveTimesheet(sheet: Timesheet): Promise<Timesheet> {
    return this.post<Timesheet>('api/timesheet/save', sheet);
  }

  public async recallTimesheet(sheet: Timesheet): Promise<Timesheet> {
    return this.post<Timesheet>('api/timesheet/recall', sheet);
  }
}

export function CrmRepositoryPlugin(Vue: typeof _Vue, options: RepositoryPluginOptions): void {
  Vue.prototype.$crmApi = new CrmRepository(Vue.prototype.$msal as AuthenticationContext, options);
}

declare module 'vue/types/vue' {
  interface Vue {
    $crmApi: CrmRepository;
  }
}
