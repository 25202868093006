import Vue from 'vue';
import { Component, Inject, Prop, Emit } from 'vue-property-decorator';
import { AddCommentData } from '@/models/add-comment-data';

@Component
export default class AddCommentModal extends Vue {
  @Prop()
  private commentData: AddCommentData;

  @Emit()
  private updateComment(data: AddCommentData) {
  }

  private focusText() {
    (this.$refs.commentfield as HTMLTextAreaElement).focus();
  }

  private submitComment() {
    this.updateComment({
      comment: (this.$refs.commentfield as HTMLTextAreaElement).value,
      groupName: this.commentData.groupName,
      taskId: this.commentData.taskId,
      show: false,
    });
  }
}
