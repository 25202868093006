import Vue from 'vue';
import { Component, Watch, Prop, Inject, Emit } from 'vue-property-decorator';
import { TimesheetSummary } from '@/models/timesheet-summary';
import { DateRange } from '@/models/date-range';
import { DateFilter } from '@/models/date-filter';
import { TimesheetAction } from '@/models/timesheet-action';

import WeekOverview from '@/components/week-overview.vue';
import WeekOverviewHeader from '@/components/week-overview-header.vue';

@Component({
  components: {
    WeekOverview,
    WeekOverviewHeader,
  },
})
export default class Startpage extends Vue {
  private timesheets: TimesheetSummary[] = [];

  private filter: DateFilter = DateFilter.Next3Monts;

  @Prop()
  private executeAction: TimesheetAction;

  private async beforeMount() {
    await this.loadData();
    document.title = 'Overview - Madaster timesheets';
  }

  private async getSheetsByDates(filter: DateFilter) {
    this.filter = filter;
    localStorage.dateFilter = filter;
    await this.loadData();
  }

  private changeSortOrder() {
    this.timesheets = this.timesheets.reverse();
  }

  private created() {
    if (!localStorage.dateFilter) {
      localStorage.dateFilter = DateFilter.Next3Monts;
    }

    // tslint:disable-next-line:radix
    this.filter = Number.parseInt(localStorage.dateFilter);
  }

  private async loadData() {
    const today: Date = new Date(Date.now());
    const range: DateRange = {
      start: undefined,
      end: new Date(Date.now()),
    };

    switch (this.filter) {
      case DateFilter.CurrentAndLast3Months:
        range.start = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case DateFilter.Next6AndLast3Months:
        range.start = new Date(today.setMonth(today.getMonth() - 3));
        range.end = new Date(today.setMonth(today.getMonth() + 9));
        break;
      case DateFilter.Last6Months:
        range.start = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case DateFilter.Last12Months:
        range.start = new Date(today.setMonth(today.getMonth() - 12));
        break;
      case DateFilter.Next3Monts:
        range.start = new Date(today.setDate(today.getDate() - 14));
        range.end = new Date(today.setMonth(today.getMonth() + 3));
        break;
      default:
        range.end = undefined;
        break;
    }

    try {
      this.timesheets = await this.$crmApi.getTimesheetsAsync(range);
    } catch { }
  }

  @Emit()
  private actionExecuted() {}

  @Watch('executeAction')
  private async onExecuteActionChanged(val: TimesheetAction, oldVal: TimesheetAction) {
    if (val === TimesheetAction.Refresh) {
      await this.loadData();
    }

    this.actionExecuted();
  }
}
