import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { MessageType } from '@/models/message-type';
import { MessageToUser } from '@/models/message-to-user';

import { RootModule } from './root-module';

Vue.use(Vuex);

const store: StoreOptions<RootModule> = {
  state: {
    isLoading: false,
    message: {
      type: MessageType.NoMessage,
      message: '',
    },
    showPlanned: localStorage.getItem('showPlanned') !== null,
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.isLoading = loading;
    },
    setMessage(state, message: MessageToUser) {
      state.message = message;
    },
    dismissMessage(state) {
      state.message.type = MessageType.NoMessage;
    },
    toggleShowPlanning(state) {
      state.showPlanned = !state.showPlanned;
      if (!state.showPlanned) {
        localStorage.removeItem('showPlanned');
      } else {
        localStorage.setItem('showPlanned', 'Met dank aan het gehele team: Milou, Rutger, Anco, Erik & Stephanie');
      }
    },
  },
  getters: {
    isLoading(state): boolean {
      return state.isLoading;
    },
    getMessage(state): MessageToUser {
      return state.message;
    },
    showPlannedHours(state): boolean {
      return state.showPlanned;
    },
  },
};

export default new Vuex.Store<RootModule>(store);
