import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import { Component, Inject, Prop, Emit } from 'vue-property-decorator';
import { Timesheet } from '@/models/timesheet';
import { PossibleTask } from '@/models/possible-task';

@Component({ mixins: [Vue2Filters.mixin] })
export default class AddTask extends Vue {
  private pickedTaskId = '';

  @Prop()
  private timesheet: Timesheet;

  get tasks(): PossibleTask[] {
    return this.timesheet.possibleTasks;
  }

  @Emit()
  private taskPicked(id: string) {
  }

  private onShow() {
    this.pickedTaskId = '';
  }

  private okClicked() {
    this.taskPicked(this.pickedTaskId);
  }
}
