import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TimesheetInfo } from '@/models/timesheet-info';
import { TimesheetAction } from '@/models/timesheet-action';

import NavBar from '@/components/nav-bar.vue';
import LoadingOverlay from '@/components/loading-overlay.vue';
import MessageCenter from '@/components/message-center.vue';

@Component({ components: { NavBar, LoadingOverlay, MessageCenter } })
export default class TimesheetApp extends Vue {
  private action: TimesheetAction = TimesheetAction.None;

  private activeTimesheet: any = {};

  private timesheetLoaded(data: TimesheetInfo) {
    this.activeTimesheet = data;
    this.action = TimesheetAction.None;
  }

  private executeTimesheetAction(data: TimesheetAction) {
    this.action = data;
  }

  private timesheetActionExecuted() {
    this.action = TimesheetAction.None;
  }
}
