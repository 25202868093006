import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { Day } from '@/models/day';
import { UpdateTaskMessage } from '@/models/update-task-message';
import { Getter } from 'vuex-class';

@Component
export default class TimesheetDay extends Vue {
  private valid = true;

  @Prop()
  private dayInfo: Day;

  @Prop()
  private groupName: string;

  @Prop()
  private taskId: string;

  @Prop()
  private editable: boolean;

  @Prop()
  private isHoliday: boolean;

  @Getter('showPlannedHours')
  private showPlannedHours: boolean;

  @Emit()
  private updateTask(data: UpdateTaskMessage) {
  }

  get plannedHours(): string {
    if (!this.showPlannedHours) {
      return '';
    }

    if (this.dayInfo.plannedHours !== null && this.dayInfo.plannedHours !== undefined) {
      return this.dayInfo.plannedHours.toString();
    }
    return '';
  }

  private noMinus(e: Event) {
    e.preventDefault();
  }

  private hoursChanged(e: Event) {
    const hours = (e.target as HTMLInputElement).value;
    this.valid = Number(hours) <= 24 && Number(hours) >= 0;
    (e.target as HTMLInputElement).value = parseFloat(hours).toFixed(2);

    this.updateTask({
      groupName: this.groupName,
      taskId: this.taskId,
      dayNumber: this.dayInfo.dayNumber,
      hours: Number((e.target as HTMLInputElement).value),
    });
  }
}
