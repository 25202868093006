import Vue from 'vue';
import Router from 'vue-router';

import Startpage from '@/views/startpage.vue';
import TimesheetComponent from '@/views/timesheet.vue';

import Profile from '@/views/profile.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Startpage,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/timesheet/:timesheetId',
      name: 'timesheet',
      component: TimesheetComponent,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/me',
      name: 'profile',
      component: Profile,
      meta: {
        requireAuth: true,
      },
    },
  ],
});
