import axios, { AxiosInstance, AxiosResponse, AxiosPromise } from 'axios';
import store from '@/store';
import { MessageType } from '@/models/message-type';
import { AuthenticationContext } from '@/plugins/msal';

export interface RepositoryPluginOptions {
  baseUrl: string;
  scopes: string[];
}

export abstract class BaseRepository {
  private axios: AxiosInstance;
  private options: RepositoryPluginOptions;
  private msal: AuthenticationContext;

  constructor(msal: AuthenticationContext, options: RepositoryPluginOptions) {
    this.msal = msal;
    this.options = options;
    this.axios = axios.create({
      baseURL: options.baseUrl,
    });
  }

  public async get<T>(url: string): Promise<T> {
    store.commit('setLoading', true);
    const token = await this.msal.getToken();
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    return new Promise<T>((resolve, reject) => {
      this.axios.get<T>(url, config).then((value) => {
        store.commit('setLoading', false);
        resolve(value.data);
      }).catch((reason) => {
        store.commit('setLoading', false);
        store.commit('setMessage', reason.response !== undefined ? reason.response.data : { type: MessageType.Error, message: reason.message });
        reject(reason);
      });
    });
  }

  public async post<T>(url: string, data?: any): Promise<T> {
    store.commit('setLoading', true);
    const token = await this.msal.getToken();
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    return new Promise<T>((resolve, reject) => {
      this.axios.post<T>(url, data, config).then((value) => {
        store.commit('setLoading', false);
        resolve(value.data);
      }).catch((reason) => {
        store.commit('setLoading', false);
        store.commit('setMessage', reason.response !== undefined ? reason.response.data : { type: MessageType.Error, message: reason.message });
        reject(reason);
      });
    });
  }
}
