import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import { Component, Inject, Prop, Watch, Emit, Mixins } from 'vue-property-decorator';
import { Timesheet } from '@/models/timesheet';
import { TimesheetStatus } from '@/models/timesheet-status';
import { TimesheetInfo } from '@/models/timesheet-info';
import { TimesheetAction } from '@/models/timesheet-action';
import { TimesheetTask } from '@/models/timesheet-task';
import { UpdateTaskMessage } from '@/models/update-task-message';
import { Day } from '@/models/day';
import { AddCommentData } from '@/models/add-comment-data';

import TimesheetHeader from '@/components/timesheet-header.vue';
import TimesheetDay from '@/components/timesheet-day.vue';
import AddTask from '@/components/add-task.vue';
import AddCommentModal from '@/components/add-comment-modal.vue';
import { MessageType } from '@/models/message-type';

@Component({ components: { TimesheetHeader, AddTask, TimesheetDay, AddCommentModal }, mixins: [Vue2Filters.mixin] })
export default class TimesheetComponent extends Vue {
  private timesheet: Timesheet = {
    timesheetId: '',
    name: '',
    availabilities: [],
    endDate: '',
    isEditable: true,
    owner: {
      employeeId: '',
      name: '',
    },
    possibleTasks: [],
    startDate: '',
    status: TimesheetStatus.Draft,
    timesheetTaskGroups: [],
    period: '',
  };

  private commentData: AddCommentData = {
    show: false,
    comment: '',
    groupName: '',
    taskId: '',
  };

  @Prop()
  private executeAction: TimesheetAction;

  private updateTask(data: UpdateTaskMessage) {
    const task = this.getTask(data.groupName, data.taskId);
    if (task === undefined) {
      return;
    }

    const day = task.days.find((d) => d.dayNumber === data.dayNumber);
    if (day === undefined) {
      return;
    }

    day.actualHours = data.hours;
    this.timesheetLoaded(this.getTimesheetInfo());
  }

  private taskTotalPlannedHours(groupName: string, taskId: string): number {
    const task = this.getTask(groupName, taskId);
    if (task === undefined) {
      return 0;
    }

    return task.days.reduce((a, b) => a + (b && b.plannedHours ? b.plannedHours : 0), 0);
  }

  private taskTotalWrittenHours(groupName: string, taskId: string): number {
    const task = this.getTask(groupName, taskId);
    if (task === undefined) {
      return 0;
    }

    return this.round(task.days.reduce((a, b) => a + (b && b.actualHours ? b.actualHours : 0), 0));
  }

  private totalDayHours(dayNumber: number): number {
    return this.round(this.getAllDays().filter((y) => y.dayNumber === dayNumber).reduce((a, b) => a + (b && b.actualHours ? b.actualHours : 0), 0));
  }

  private totalPlannedHours(): number {
    return this.round(this.getAllDays().reduce((a, b) => a + (b && b.plannedHours ? b.plannedHours : 0), 0));
  }

  private totalWrittenHours(): number {
    return this.round(this.getAllDays().reduce((a, b) => a + (b && b.actualHours ? b.actualHours : 0), 0));
  }

  private checkIfTimesheetIsValid(): boolean {
    return this.getAllDays().filter((d) => d.actualHours != null && (d.actualHours < 0 || d.actualHours > 24)).length === 0;
  }

  private isHoliday(dayNumber: number): boolean {
    return this.timesheet.availabilities.find((a) => a.dayNumber === dayNumber && a.isHoliday) !== undefined;
  }

  @Emit()
  private timesheetLoaded(data: TimesheetInfo) {
  }

  @Emit()
  private async actionExecuted() {
    this.timesheetLoaded(this.getTimesheetInfo());
  }

  private async beforeMount() {
    try {
      this.timesheet = await this.$crmApi.getTimesheetAsync(this.$route.params.timesheetId);
      this.timesheetLoaded(this.getTimesheetInfo());

      document.title = `${this.timesheet.name} - Madaster timesheets`;
    } catch {}
  }

  private showCommentDialog(groupName: string, taskId: string, comment: string) {
    this.commentData = {
      comment,
      groupName,
      taskId,
      show: true,
    };
  }

  private updateComment(data: AddCommentData) {
    const task = this.getTask(data.groupName, data.taskId);
    if (task === undefined) {
      return;
    }

    task.comment = data.comment;
  }

  private addNewTask(taskId: string) {
    const taskToAdd = this.timesheet.possibleTasks.find((t) => t.taskId === taskId);
    if (taskToAdd !== undefined) {
      let group = this.timesheet.timesheetTaskGroups.find((g) => g.groupName === 'Administratief');
      if (group === undefined) {
        group = {
          groupName: 'Administratief',
          timesheetTasks: [],
        };

        this.timesheet.timesheetTaskGroups.push(group);
      }

      const days: Day[] = [];
      this.timesheet.availabilities.forEach((a) => {
        days.push({
          timesheetTaskDayId: '00000000-0000-0000-0000-000000000000',
          day: a.day,
          dayNumber: a.dayNumber,
          isEditable: true,
          actualHours: null,
          plannedHours: null,
        });
      });

      const newTask: TimesheetTask = {
        customer: 'Administratief',
        project: 'Administratief',
        projectId: '',
        comment: '',
        days,
        isAdministrative: taskToAdd.isAdministrative,
        task: taskToAdd.task,
        taskId: taskToAdd.taskId,
        startDate: this.timesheet.availabilities[0].day,
        owner: this.timesheet.owner,
      };
      group.timesheetTasks.push(newTask);

      this.timesheet.possibleTasks = this.timesheet.possibleTasks.filter((t) => t.taskId !== taskId);
    }
  }

  @Watch('executeAction')
  private async onExecuteActionChanged(val: TimesheetAction, oldVal: TimesheetAction) {
    switch (val) {
      case TimesheetAction.Save: {
        try {
          this.timesheet = await this.$crmApi.saveTimesheet(this.timesheet);
          this.$store.commit('setMessage', { type: MessageType.Information, message: this.$t('messages.timesheetsaved').toString() });
        } catch { }
        break;
      }

      case TimesheetAction.Submit: {
        try {
          this.timesheet = await this.$crmApi.submitTimesheet(this.timesheet);
          this.$store.commit('setMessage', { type: MessageType.Information, message: this.$t('messages.timesheetsubmitted').toString() });
        } catch { }
        break;
      }

      case TimesheetAction.Recall: {
        try {
          this.timesheet = await this.$crmApi.recallTimesheet(this.timesheet);
        } catch { }
        break;
      }

      default: {
        return;
      }
    }
    await this.actionExecuted();
  }

  private getTimesheetInfo(): TimesheetInfo {
    return {
      timesheetId: this.timesheet.timesheetId,
      name: this.timesheet.name,
      timesheetStatus: this.timesheet.status,
      valid: this.checkIfTimesheetIsValid(),
      submitEnabled: this.totalWrittenHours() >= 40,
      startDate: this.timesheet.startDate,
      endDate: this.timesheet.endDate,
      period: this.timesheet.period,
    };
  }

  private getAllDays(): Day[] {
    return this.timesheet.timesheetTaskGroups.reduce((a, b) => a.concat(b.timesheetTasks), [] as TimesheetTask[]).reduce((a, b) => a.concat(b.days), [] as Day[]);
  }

  private getTask(groupName: string, taskId: string): TimesheetTask | undefined {
    const group = this.timesheet.timesheetTaskGroups.find((g) => g.groupName === groupName);
    if (group === undefined) {
      return undefined;
    }

    return group.timesheetTasks.find((t) => t.taskId === taskId);
  }

  private round(num: number): number {
    return Math.round(num * 100) / 100;
  }
}
