import Vue from 'vue';
import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { MessageToUser } from '@/models/message-to-user';
import { MessageType } from '@/models/message-type';
import { String, StringBuilder } from 'typescript-string-operations';
import { Getter } from 'vuex-class';

@Component
export default class MessageCenter extends Vue {
  @Getter('getMessage')
  private messageToUser: MessageToUser;

  private showModal = false;

  private alertCountdown = 0;

  get message(): string {
    if (this.messageToUser.errorCode && this.messageToUser.errorCode !== '') {
      let message = this.$t(`errormessage.${this.messageToUser.errorCode}`).toString();
      if (this.messageToUser.errorParams && this.messageToUser.errorParams.length > 0) {
        message = String.Format(message, ...this.messageToUser.errorParams);
        return message;
      }
      return message;
    }
    return this.messageToUser.message;
  }

  get showMessageType(): MessageType {
    return this.messageToUser.type;
  }

  get modalTitle(): string {
    if (this.showMessageType === MessageType.Warning) {
      return this.$t('messages.warning').toString();
    }
    return this.$t('messages.error').toString();
  }

  private countDownChanged(count: number) {
    this.alertCountdown = count;
  }

  private hideMessage() {
    this.showModal = false;
    this.alertCountdown = 0;
    return this.$store.commit('dismissMessage');
  }

  @Watch('showMessageType')
  private messageChanged(val: MessageType, oldVal: MessageType) {
    switch (val) {
      case MessageType.Information : {
        this.alertCountdown = 7;
        break;
      }

      case MessageType.Warning : {
        this.showModal = true;
        break;
      }

      case MessageType.Error : {
        this.showModal = true;
        break;
      }

      default: {
        this.alertCountdown = 0;
        this.showModal = false;
        break;
      }
    }
  }
}
