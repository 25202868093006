import _Vue from 'vue';
import dayjs from 'dayjs';
import './nl';

export interface DayjsOptions {
  locale: string;
}

export function Dayjs<opt>(Vue: typeof _Vue, options: DayjsOptions): void {
  dayjs.locale(options.locale);
  Vue.prototype.$dayjs = dayjs;
  Vue.prototype.$daylocale = dayjs.locale;

  Vue.filter('dayjs', (...params: any[]) => {
    const args = params.slice();
    const input = args.shift();
    let day: dayjs.Dayjs;
    let result: any;

    if (Array.isArray(input) && typeof input[0] === 'string') {
      // If input is array, assume we're being passed a format pattern to parse against.
      // Format pattern will accept an array of potential formats to parse against.
      // Date string should be at [0], format pattern(s) should be at [1]
      day = dayjs(input[0], { format: input[1], utc: true });
    } else if (typeof input === 'number') {
      if (input.toString().length < 12) {
        // If input is an integer with fewer than 12 digits, assume Unix seconds...
        day = dayjs.unix(input);
      } else {
        // ..otherwise, assume milliseconds.
        day = dayjs(input);
      }
    } else {
      day = dayjs(input);
    }

    if (!input || !day.isValid()) {
      // console.warn('Could not build a valid `dayjs` object from input.');
      return input;
    }

    // tslint:disable-next-line:no-shadowed-variable
    function parse(...args: any[]) {
      const method = args.shift();
      switch (method) {
        default: {
          result = day.format(method);
        }
      }

      if (args.length) {
        parse.apply(parse, args);
      }
    }

    parse.apply(parse, args);

    return result;
  });
}

declare module 'vue/types/vue' {
  interface Vue {
    $dayjs(date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
    $daylocale(preset: string | { name: string; [key: string]: any }, object?: { [key: string]: any }, isLocal?: boolean): string;
  }
}
