import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { TimesheetAction } from '@/models/timesheet-action';
import { TimesheetInfo } from '@/models/timesheet-info';
import { TimesheetStatus } from '@/models/timesheet-status';
import { Getter, Mutation } from 'vuex-class';

@Component
export default class NavBar extends Vue {
  @Prop()
  private timesheetInfo: TimesheetInfo | undefined;

  @Getter('showPlannedHours')
  private showPlannedHours: boolean;

  @Mutation('toggleShowPlanning')
  private toggleShowPlanning() {
  }

  get isRecallDisabled(): boolean {
    return this.timesheetInfo !== undefined && this.timesheetInfo.timesheetStatus !== TimesheetStatus.Submitted;
  }

  get isSaveDisabled(): boolean {
    return this.timesheetInfo !== undefined && (!this.timesheetInfo.valid ||
            (this.timesheetInfo.timesheetStatus === TimesheetStatus.Submitted));
  }

  get isSubmitDisabled(): boolean {
    return this.timesheetInfo !== undefined && (!this.timesheetInfo.valid || !this.timesheetInfo.submitEnabled || this.timesheetInfo.timesheetStatus !== TimesheetStatus.Draft);
  }

  get timePeriod(): string {
    if (this.timesheetInfo === undefined) {
      return '';
    }

    return `${this.$dayjs(this.timesheetInfo.startDate).format('dd D MMM ')} ${this.$t('navbar.fromto')} ${this.$dayjs(this.timesheetInfo.endDate).format('dd D MMM \'YY')}`;
  }

  @Emit()
  private executeAction(data: TimesheetAction) {
  }

  private Save() {
    this.executeAction(TimesheetAction.Save);
  }

  private Submit() {
    this.executeAction(TimesheetAction.Submit);
  }

  private Recall() {
    this.executeAction(TimesheetAction.Recall);
  }

  private Refresh() {
    this.executeAction(TimesheetAction.Refresh);
  }
}
