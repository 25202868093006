import Vue from 'vue';
import { Component, Inject, Emit } from 'vue-property-decorator';
import { DateFilter } from '@/models/date-filter';

@Component
export default class WeekOverviewHeader extends Vue {
  private filterSelected: DateFilter;

  private dateOptions: { [dateText: string]: DateFilter } = {
    'Komende 3 maanden + vorige week': DateFilter.Next3Monts,
    'Huidige + afgelopen 3 maanden': DateFilter.CurrentAndLast3Months,
    'Komende 6 maanden + afgelopen 3 maanden': DateFilter.Next6AndLast3Months,
    'Afgelopen 6 maanden': DateFilter.Last6Months,
    'Afgelopen 12 maanden': DateFilter.Last12Months,
    Alles: DateFilter.All,
  };

  @Emit()
  private dateSelected(filter: DateFilter) {
  }

  @Emit()
  private changeSortOrder() {
  }
}
