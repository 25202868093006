import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class/lib/bindings';

@Component
export default class LoadingOverlay extends Vue {
  @Getter('isLoading')
  private showLoader: boolean;

  private isFullPage = true;

  private backgroundColor = '#fff';

  private opacity = 0.5;

  private transition = 'fade';
}
